import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="turbo-spinner"
export default class extends Controller {
  static targets = ["spinner"];

  connect() {
    document.addEventListener("turbo:frame-load", () => {
      this.hideSpinner();
    });

    document.addEventListener("turbo:frame-render", () => {
      this.hideSpinner();
    });

    document.addEventListener("turbo:fetch-request-error", () => {
      this.hideSpinner();
    });
  };

  showSpinner() {
    if (!this.hasSpinnerTarget) return;

    this.spinnerTarget.classList.remove("d-none");
  };

  hideSpinner() {
    if (!this.hasSpinnerTarget) return;

    this.spinnerTarget.classList.add("d-none");
  };
};