import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"
import { Portuguese} from "flatpickr/dist/l10n/pt.js"


// Connects to data-controller="flatpickr"
export default class extends Controller {
  static targets = ["flatpickr"];

  connect() {
    this.config = {
      enableTime: true,
      dateFormat: "d-m-Y H:i",
      "locale": Portuguese,
    }
    flatpickr(this.flatpickrTarget, this.config)
  }
}

