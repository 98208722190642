import { Controller } from "@hotwired/stimulus"
import { setCookie } from "../services/cookies"

// Connects to data-controller="nav-tab-cookie-loader"
export default class extends Controller {
  connect() {
    const cookieName = this.element.getAttribute("data-nav-tab-cookie-loader-name") || "nav-tab-cookie-loader";

    this.element.addEventListener("shown.bs.tab", (event) => {
      if (this.element === event.target.closest("[data-controller=nav-tab-cookie-loader]")) {
        const cookieValue = event.target.getAttribute("data-bs-target").slice(1);

        setCookie(cookieName, cookieValue);
      };
    });

    window.addEventListener("unload", () => {
      // regex para saber se a raiz do path é a mesma: não mudar quando for pra edit
      document.cookie = `${cookieName}=; Max-Age=-99999999`;
    });
  };
};
