import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="hidden-checkbox"
export default class extends Controller {
  static targets = ["checkbox"];

  check() {
    this.checkboxTarget.checked = true
  };
};
