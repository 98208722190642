function handleContentChange(mutationsList, observer) {
  for (let mutation of mutationsList) {
    if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
      mutation.addedNodes.forEach((node) => {
	      if (node.classList) {
          if (node.classList.contains("turbo-frame-error")) {
            node.innerHTML = '<div class="placeholder-glow"><span class="placeholder w-100"></span></div>';
          };
	      };
      });
    };
  };
};

const observer = new MutationObserver(handleContentChange);
const config = { childList: true, subtree: true };

observer.observe(document, config);
