import { Controller } from "@hotwired/stimulus"

function scrollToBottom() {
  const height = document.documentElement.scrollHeight;
  window.scrollTo(0, height);
}

// Connects to data-controller="focus"
export default class extends Controller {
  static targets = ["toggle", "input"];

  toggleTargetConnected(target) {
    target.addEventListener("click", () => {
      const commentField = document.getElementById("comment_comment");
      commentField.focus();
      setTimeout(scrollToBottom, 200);
    });
  };

  connect() {
    if (this.hasInputTarget) {
      this.inputTargets.forEach((input, index) => {
        input.addEventListener("input", () => {
          this.passFocus(index);
        });
      });
    };
  };

  passFocus(currentIndex) {
    const nextIndex = currentIndex + 1;
    if (nextIndex < this.inputTargets.length) { 
      const loadHandler = () => {
        this.inputTargets[nextIndex].addEventListener("input", () => {
          this.passFocus(nextIndex);
        });

        this.inputTargets[nextIndex].focus();
      };
  
      document.documentElement.addEventListener("turbo:frame-load", loadHandler);
    };
  };
};
