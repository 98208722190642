import { Controller } from "@hotwired/stimulus"
import { setCookie, getCookie, eraseCookie } from "../services/cookies"

// Connects to data-controller="cookie-permission"
export default class extends Controller {
  static targets = ["message"]

  messageTargetConnected(target) {
    this.toggleCookieMessage(target)

    window.addEventListener("cookiechange", () => {
      this.toggleCookieMessage(target)
    })
  }

  toggleCookieMessage(target) {
    if (this.isCookiesAllowed()) {
      target.classList.add("d-none")
    } else {
      target.classList.remove("d-none")
    }
  }

  acceptCookies() {
    const twentyYears = 7305

    setCookie("cookies_accepted", true, { days: twentyYears, path: "/" })

    dispatchEvent(new Event("cookiechange"))
  }

  rejectCookies() {
    eraseCookie("cookies_accepted", "/")
    eraseCookie("theme_color", "/")

    dispatchEvent(new Event("cookiechange"))
  }

  isCookiesAllowed() {
    return getCookie("cookies_accepted")
  }
}
