import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="binded-select"
export default class extends Controller {
  static targets = ["hasMany", "belongsTo"];

  optionsTree;

  belongsToTargetConnected() {
    this.optionsTree = { ...this.belongsToTarget.children };
  };

  updateBelongsTo() {
    const groupKey = this.hasManyTarget.querySelectorAll("option")[0].innerText;
    const optionsTreeCopy = { ...this.optionsTree };

    let optionsIndex = Object.keys(optionsTreeCopy);
    const placeholder = optionsTreeCopy[0];
    let updatedOptions;

    optionsIndex.forEach((index) => {
      if (optionsTreeCopy[index].getAttribute("label") == groupKey) {
        updatedOptions = optionsTreeCopy[index];
      };
    });

    this.belongsToTarget.replaceChildren(updatedOptions);
    this.belongsToTarget.appendChild(placeholder);
  };
};
