export async function validateCPF(cpf) {
    cpf = cpf.replace(/[^\d]/g, '');

    if (cpf.length !== 11) {
        return false;
    }

    if (/^(\d)\1{10}$/.test(cpf)) {
        return false;
    }

    var sum = 0;
    for (var i = 0; i < 9; i++) {
        sum += parseInt(cpf.charAt(i)) * (10 - i);
    }
    var remainder = sum % 11;
    var verificationDigit1 = (remainder < 2) ? 0 : 11 - remainder;

    sum = 0;
    for (var i = 0; i < 10; i++) {
        sum += parseInt(cpf.charAt(i)) * (11 - i);
    }
    remainder = sum % 11;
    var verificationDigit2 = (remainder < 2) ? 0 : 11 - remainder;

    return (parseInt(cpf.charAt(9)) === verificationDigit1 && parseInt(cpf.charAt(10)) === verificationDigit2);
}
