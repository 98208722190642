import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clear-input"
export default class extends Controller {
  static targets = ["input"];

  clear() {
    if (this.hasInputTarget) {
      this.inputTargets.forEach((target) => {
        target.value = "";
        target.dispatchEvent(new Event("input", { bubbles: true }));
      });
    };
  };
};
