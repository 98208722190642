import { Controller } from "@hotwired/stimulus"
import translations from "../locales/pt-BR.json";

// Connects to data-controller="simple-spinner"
export default class extends Controller {
  start() {
    this.element.innerHTML = this.#spinnerElement() + this.#spinnerAction();
    this.element.disabled  = true;
  };

  #spinnerElement() {
    let spinner = document.createElement("span");

    spinner.classList.add("spinner-border");
    spinner.classList.add("spinner-border-sm");

    return spinner.outerHTML;
  };

  #spinnerAction() {
    let action = document.createElement("span");

    action.classList.add("ps-1");
    action.textContent = translations["pt-BR"]["javascript"]["actions"]["searching"];

    return action.outerHTML;
  }
};
