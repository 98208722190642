import { Controller } from "@hotwired/stimulus"
import { freezeScrollOnNextRender } from "../services/freeze_scroll_turbo_render";

// Connects to data-controller="click"
export default class extends Controller {
  static targets = ["click"];

  connect() {
    if (this.element.hasAttribute("data-click-strategy")) {
      if (this.element.getAttribute("data-click-strategy") == "onConnection") this.click();
    };
  };

  click() {
    freezeScrollOnNextRender();
    setTimeout(() => this.clickTargets.forEach(target => target.click()), 200);
  };
};
