import { addClass } from "./addClass";

export class Modal {
  constructor() {
    this.wrapper = this.#createWrapper();
    this.dialog = this.#createDialog();
    this.content = this.#createContent();
    this.header = this.#createHeader();
    this.title = this.#createTitle();
    this.body = this.#createBody();
    this.footer = this.#createFooter();

    this.#build();
  };

  render() {
    return this.wrapper;
  };

  setModalSize(size) {
    const sizes = {
      "small": "modal-sm",
      "default": "",
      "large": "modal-lg",
      "extra large": "modal-xl"
    };

    const modalSize = sizes[size];

    if (modalSize) {
      addClass(this.dialog, modalSize);
    } else {
      addClass(this.dialog, sizes["default"]);
    };
  };

  #build() {
    this.header.append(this.title);
    this.header.append(this.#createExpandButton())
    this.content.append(this.header);
    this.content.append(this.body);
    this.content.append(this.footer);
    this.dialog.append(this.content);
    this.wrapper.append(this.dialog);
  };

  #createWrapper() {
    let wrapper = document.createElement("div");

    addClass(wrapper, "modal fade");
    wrapper.setAttribute("tabindex", "-1");
    wrapper.setAttribute("data-bs-backdrop", "static");
    wrapper.setAttribute("data-controller", "modal");

    return wrapper;
  };

  #createDialog() {
    let dialog = document.createElement("div");

    addClass(dialog, "modal-dialog modal-dialog-centered");

    return dialog;
  };

  #createContent() {
    let content = document.createElement("div");

    addClass(content, "modal-content");

    return content;
  };

  #createHeader() {
    let header = document.createElement("div");

    addClass(header, "modal-header small fw-bold bg-100 py-2");

    return header;
  };

  #createExpandButton() {
    let button = document.createElement("button");

    button.type = "button";
    button.title = "Expandir";
    button.setAttribute("data-controller", "icon-toggler title-toggler");
    button.setAttribute("data-icon-toggler-icons-param", '["bi bi-arrows-angle-expand", "bi bi-arrows-angle-contract"]');
    button.setAttribute("data-title-toggler-titles-param", '["Expandir", "Contrair"]');
    button.setAttribute("data-action", "icon-toggler#toggle title-toggler#toggle modal#toggleModalFullscreen");
    addClass(button, "btn")

    let icon = document.createElement("i");

    addClass(icon, "bi bi-arrows-angle-expand");

    button.append(icon);

    return button;
  };

          // <div class="modal-content vh-100">
          // <div class="modal-header bg-100 py-2 d-flex justify-content-between">
          //   <%= tag.h5(@modal_title || ui_title(model_name, action_name), class: "modal-title text-truncate") %>

          //   <div class="btn-group">
          //     <button type="button" class="btn" title = "<%= t("dry.action.expand") %>"
          //             data-controller="icon-toggler title-toggler"
          //             data-icon-toggler-icons-param='["bi bi-arrows-angle-expand", "bi bi-arrows-angle-contract"]'
          //             data-title-toggler-titles-param='<%= [t("dry.action.expand"), t("dry.action.retract")] %>'
          //             data-action="icon-toggler#toggle title-toggler#toggle modal#toggleModalFullscreen">
          //       <i class="bi bi-arrows-angle-expand"></i>
          //     </button>
          //     <button type="button" class="btn bi bi-x-lg" title=<%= t("dry.action.exit") %> data-action="modal#hideModal"></button>
          //   </div>
          // </div>
          // <div class="modal-body py-3">
          //   <%= yield %>
          // </div>
        // </div>


  #createTitle() {
    let title = document.createElement("h5");

    addClass(title, "modal-title");

    return title;
  };

  #createBody() {
    let body = document.createElement("div");

    addClass(body, "modal-body");

    return body;
  };

  #createFooter() {
    let footer = document.createElement("div");

    addClass(footer, "modal-footer d-flex justify-content-center bg-100");

    return footer;
  };
};
