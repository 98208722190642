import consumer from "./consumer";

document.addEventListener("DOMContentLoaded", function() {
  let current_user_id = parseInt(document.getElementById("notification-badge").dataset.id);

  setInterval(function() {
    document.getElementById("notification-icon").addEventListener("click", function() {
            updateNotificationBadgeVisibility(false);
      });
    }, 1);

  consumer.subscriptions.create({ channel: "NotificationsChannel"}, {
      connected() {
          updateNotificationBadgeVisibility(getNotificationBadgeVisibility());
      },

      disconnected() {
          updateNotificationBadgeVisibility(getNotificationBadgeVisibility());
      },

      received(data) {
          if (!isNaN(current_user_id) && Number.isInteger(current_user_id) && current_user_id > 0) {
              current_user_id = parseInt(current_user_id);
              if (data.user_ids.includes(current_user_id)) {
                  updateNotificationBadgeVisibility(true);
                  setInterval(function() {
                    updateNotificationBadgeVisibility(getNotificationBadgeVisibility());
                }, 0);
              }
          }
      }
  });

  function updateNotificationBadgeVisibility(visible) {
    let notificationBadge = document.getElementById("notification-badge");
    if (notificationBadge) {
        notificationBadge.hidden = !visible;
        localStorage.setItem("notificationBadgeVisible", visible ? "true" : "false");
    }
}

  function getNotificationBadgeVisibility() {
      return localStorage.getItem("notificationBadgeVisible") === "true";
  }
});