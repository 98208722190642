import { Controller } from "@hotwired/stimulus";
import { redirectTo } from "../services/redirect_to";

// Connects to data-controller="remote-certificate"
export default class extends Controller {
  static targets = ["autoCall", "message", "submit"];

  autoCallTargetConnected(target) {
    target.click();
  };

  async sendMessage({ params: { message, reference, server, callback, errorpath } }) {
    const url = `http://localhost:1234/message`
    this.messageTarget.value = "conectando..."
    
    try {
      
      const options= { method: "POST",
                       body: message}
      const response = await fetch(url,options);

      if (response.ok) {
        const jsonResponse = await response.json();
        
          jsonResponse.reference = reference
          //redirectTo(`${server}${callback}?data=${encodeURIComponent(btoa(JSON.stringify(jsonResponse)))}&reference=${encodeURIComponent(reference)}`);
          this.messageTarget.value = JSON.stringify(jsonResponse)
          this.submitTarget.click()
      } else {
          alert('Erro na requisição:', response.status, response.statusText);
      }
    } catch (error) {
        let error_message;
        error_message = "acsiv_signer_not_found"
        redirectTo(`${server}${errorpath}?error_message=${error_message}`);
    }
  };
};
