import { Controller } from "@hotwired/stimulus";
import { addClass } from "../services/addClass";
import { Modal } from "../services/modal";
import Cropper from "cropperjs";
import { useMutation } from "stimulus-use";

// Connects to data-controller="cropper"
export default class extends Controller {
  static targets = ["actionBar", "input"];

  input;
  image;
  modal;
  preview;
  blob;
  cropper;
  rotationTimer;
  zoomTimer;
  moveTimer;

  timer = 50;

  defaultSettings = {
    toggleDragModeOnDblclick: false,
    modal: false,
  };

  connect() {
    this.#movableElementSettings();
    this.#dragModeSettings();
    this.#aspectRatioSettings();
    this.#autoCropSettings();
    this.modal = new Modal;

    useMutation(this, { element: this.modal.dialog, attributes: true });
  };

  mutate(entries) {
    const cropperContainer = document.getElementsByClassName("cropper-container cropper-bg")[0];

    if (entries[0].target.classList.contains("modal-fullscreen")) {
      cropperContainer.classList.add("w-100","h-100");
    } else {
      cropperContainer.classList.remove("w-100","h-100");
    };
  };

  changeModalTitle({ params: { modalTitle } }) {
    this.modal.title.innerText = modalTitle;
  };

  modalExtraLarge() {
    this.defaultSettings["minContainerWidth"] = 1140
    this.defaultSettings["minContainerHeight"] = 570
    this.defaultSettings["minCanvasWidth"] = 1140
    this.modal.setModalSize("extra large");
  };

  #movableElementSettings() {
    let selectedOption;
    const movableElements = {
      "cropBox": { cropBoxMovable: true, movable: false },
      "image": { cropBoxMovable: false, movable: true }
    };

    if (this.element.hasAttribute("data-cropper-movable-element")) {
      selectedOption = this.element.getAttribute("data-cropper-movable-element");
    } else {
      selectedOption = "cropBox";
    };

    const movableSetting = movableElements[selectedOption];

    if (movableSetting) this.defaultSettings = { ...this.defaultSettings, ...movableSetting };
  };

  #dragModeSettings() {
    let selectedOption;
    const dragModes = {
      "move": { dragMode: "move" },
      "crop": { dragMode: "crop" }
    };

    if (this.element.hasAttribute("data-cropper-drag-mode")) {
      selectedOption = this.element.getAttribute("data-cropper-drag-mode");
    } else {
      selectedOption = "crop";
    };

    const dragModeSetting = dragModes[selectedOption];

    if (dragModeSetting) this.defaultSettings = { ...this.defaultSettings, ...dragModeSetting };
  };

  #aspectRatioSettings() {
    let selectedOption;
    const aspectRatios = {
      "1:1": { aspectRatio: 1/1, cropBoxResizable: false },
      "3:2": { aspectRatio: 3/2, cropBoxResizable: false },
      "4:3": { aspectRatio: 4/3, cropBoxResizable: false },
      "5:4": { aspectRatio: 5/4, cropBoxResizable: false },
      "16:10": { aspectRatio: 16/10, cropBoxResizable: false },
      "16:9": { aspectRatio: 16/9, cropBoxResizable: false },
      "9:16": { aspectRatio: 9/16, cropBoxResizable: false },
      "free": { aspectRatio: NaN, cropBoxResizable: true }
    };

    if (this.element.hasAttribute("data-cropper-aspect-ratio")) {
      selectedOption = this.element.getAttribute("data-cropper-aspect-ratio");
    } else {
      selectedOption = "free";
    };

    const aspectRatioSetting = aspectRatios[selectedOption];

    if (aspectRatioSetting) this.defaultSettings = { ...this.defaultSettings, ...aspectRatioSetting };
  };

  #autoCropSettings() {
    let selectedOption;
    const autoCroppingOptions = {
      "automatic": { autoCrop: true },
      "manual": { autoCrop: false }
    };

    if (this.element.hasAttribute("data-cropper-auto-crop")) {
      selectedOption = this.element.getAttribute("data-cropper-auto-crop");
    } else {
      selectedOption = "manual";
    };

    const autoCropSetting = autoCroppingOptions[selectedOption];

    if (autoCropSetting) this.defaultSettings = { ...this.defaultSettings, ...autoCropSetting };
  };

  inputTargetConnected(target) {
    this.input = target;
  };

  cropClosestImage(event) {
    if (event.target.closest("img")) {
      this.image = event.target.closest("img");
    } else {
      const anchor = event.target.closest(event.params.anchor);

      this.image = anchor.getElementsByTagName("IMG")[0];
    };

    this.#startCropping();
  };

  cropImage(event) {
    this.image = event.target;
    this.#startCropping();
  };

  #checkForDropzoneIdentifier() {
    if (this.image.hasAttribute("data-dropzone-identifier")) {
      const fileIdentifier = this.image.getAttribute("data-dropzone-identifier");
      const fileInput = document.querySelector(`[data-file-uploader-identifier="${fileIdentifier}"]`);

      this.input = fileInput;
    };
  };

  #startCropping() {
    this.#checkForDropzoneIdentifier();
    this.#renderModal();
  };

  #createCroppingContainer() {
    let container = document.createElement("div");
    let imageContainer = this.#createImageContainer();

    addClass(container, "col w-100 h-100 d-flex");
    container.append(imageContainer);

    this.#initializeCropper(imageContainer, { ...this.defaultSettings });

    return container;
  };

  #createImageContainer() {
    let imageContainer = this.image.cloneNode();
    addClass(imageContainer, "w-100");

    this.preview = imageContainer;

    return imageContainer;
  };

  #initializeCropper(image, settings = this.defaultSettings) {
    this.cropper = new Cropper(image, settings);
  };

  #addCroppingContainerToModalBody() {
    this.modal.body.append(this.#createCroppingContainer());
  };

  #addActionBarToModalFooter() {
    if (!this.hasActionBarTarget) return;

    let actionBar = this.actionBarTarget.cloneNode(true);

    actionBar.removeAttribute("hidden");
    this.modal.footer.append(actionBar);
  };

  #renderModal() {
    this.#addActionBarToModalFooter();
    this.#addCroppingContainerToModalBody();

    this.element.append(this.modal.render());
  };

  // cropper actions

  clear() {
    this.cropper.clear();
  };

  crop() {
    this.cropper.crop();
  };

  reset() {
    this.cropper.reset();
  };

  enable() {
    this.cropper.enable();
  };

  disable() {
    this.cropper.disable();
  };

  destroy() {
    this.cropper.destroy();
  };

  rotateLeft({ params: { rotateBy } }) {
    const degree = rotateBy || 1;
    let cropper = this.cropper;

    this.rotationTimer = setInterval(function() {
      cropper.rotate(-Math.abs(degree));
    }, this.timer);
  };

  rotateRight({ params: { rotateBy } }) {
    const degree = rotateBy || 1;
    let cropper = this.cropper;

    this.rotationTimer = setInterval(function() {
      cropper.rotate(Math.abs(degree));
    }, this.timer);
  };

  stopRotation() {
    if (this.rotationTimer) clearInterval(this.rotationTimer);
  };

  zoomIn({ params: { zoomBy } }) {
    const ratio = zoomBy || 0.1;
    let cropper = this.cropper;

    this.zoomTimer = setInterval(function() {
      cropper.zoom(Math.abs(ratio));
    }, this.timer);
  };

  zoomOut({ params: { zoomBy } }) {
    const ratio = zoomBy || 0.1;
    let cropper = this.cropper;

    this.zoomTimer = setInterval(function() {
      cropper.zoom(-Math.abs(ratio));
    }, this.timer);
  };

  stopZooming() {
    if (this.zoomTimer) clearInterval(this.zoomTimer);
  };

  moveUp({ params: { moveBy }}) {
    const pixels = moveBy || 10;
    let cropper = this.cropper;

    this.moveTimer = setInterval(function() {
      cropper.move(0, -Math.abs(pixels));
    }, this.timer);
  };

  moveDown({ params: { moveBy }}) {
    const pixels = moveBy || 10;
    let cropper = this.cropper;

    this.moveTimer = setInterval(function() {
      cropper.move(0, Math.abs(pixels));
    }, this.timer);
  };

  moveLeft({ params: { moveBy }}) {
    const pixels = moveBy || 10;
    let cropper = this.cropper;

    this.moveTimer = setInterval(function() {
      cropper.move(-Math.abs(pixels), 0);
    }, this.timer);
  };

  moveRight({ params: { moveBy }}) {
    const pixels = moveBy || 10;
    let cropper = this.cropper;

    this.moveTimer = setInterval(function() {
      cropper.move(Math.abs(pixels), 0);
    }, this.timer);
  };

  stopMoving() {
    if (this.moveTimer) clearInterval(this.moveTimer);
  };

  async confirmationImage() {
    await this.#saveBlob();
    this.destroy();

    if (this.preview) {
      this.#updatePreview();
    };
  };

  concludeCropping() {
    if (this.input) {
      this.#updateInputFile();
    };

    if (this.image) {
      this.#updateImage();
    };
  };

  recreateCropper() {
    this.preview.src = this.image.src;
    this.#initializeCropper(this.preview);
  };

  //

  async #saveBlob() {
    const canvas = this.cropper.getCroppedCanvas();

    this.blob = await new Promise(resolve => canvas.toBlob(resolve));
  };

  #updateInputFile() {
    const fileFromBlob = new File([this.blob], "cropped_image");
    let dataTransfer = new DataTransfer();

    dataTransfer.items.add(fileFromBlob);
    this.input.files = dataTransfer.files;
  };

  #updateImage() {
    this.image.src = window.URL.createObjectURL(this.blob);
  };

  #updatePreview() {
    this.preview.src = window.URL.createObjectURL(this.blob);
  };
};
