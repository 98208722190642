import { Controller } from "@hotwired/stimulus"
import { addClass } from "../services/addClass";
import { useMutation } from "stimulus-use"

// Connects to data-controller="dropzone"
export default class extends Controller {
  static targets = ["container", "uploaderInput", "dropzone"];

  label;
  fileStorage;
  croppable;

  connect() {
    if (this.hasUploaderInputTarget) {
      useMutation(this, { element: this.fileStorage, childList: true });
    };

    if (this.element.closest("[data-controller=cropper]")) this.croppable = true;
  };

  mutate(entries) {
    if (this.hasUploaderInputTarget) this.#toggleDropzone();
    if (this.hasUploaderInputTarget) this.#removeExcedentFiles();
  };

  containerTargetConnected(target) {
    addClass(target, "position-relative");

    target.append(this.#createClickableDropzone());
  };

  dropzoneTargetConnected(target) {
    this.fileStorage = target;
  };

  messageTargetConnected(target) {
    target.setAttribute("role", "button");
    addClass(target, "user-select-none position-absolute top-50 start-50 translate-middle text-center");
    target.setAttribute("data-action", "dragover->dropzone#dragoverFile drop->dropzone#dropFile paste->dropzone#pasteFile click->dropzone#chooseFile");
  };

  dropFile(event) {
    event.preventDefault();

    if (event.dataTransfer.items) {
      const items = event.dataTransfer.items;

      if (Object.values(items).every(item => item.kind === "file")) {
        const files = event.dataTransfer.files;

        this.#manageFiles(files);
      };
    };
  };

  dragoverFile(event) {
    event.preventDefault();
  };

  pasteFile(event) {
    if (event.clipboardData.items) {
      const files = event.clipboardData.files;

      this.#manageFiles(files);
    };
  };

  chooseFile() {
    this.label.click();
  };

  callFileManager(event) {
    const files = event.target.files;
    this.#manageFiles(files);
  };

  removeFilePreview(event) {
    event.target.closest("[data-dropzone-role=filePreview]").remove();
  };

  stopClickPropagation(event) {
    event.stopPropagation();
  };

  #createClickableDropzone() {
    let clickableDropzone = document.createElement("div");

    clickableDropzone.append(this.#createLabel());
    clickableDropzone.append(this.#createFileInput());

    return clickableDropzone;
  };

  #createLabel() {
    let label = document.createElement("label");

    label.setAttribute("for", "clickable-dropzone");
    label.setAttribute("hidden", "true");
    this.label = label;

    return label;
  };

  #createFileInput() {
    let fileInput = document.createElement("input");

    fileInput.type = "file";
    fileInput.id = "clickable-dropzone";
    fileInput.setAttribute("hidden", "true");
    fileInput.setAttribute("data-action", "dropzone#callFileManager");
    fileInput.setAttribute("accept", "image/*, application/pdf");

    if (this.uploaderInputTarget.hasAttribute("multiple")) {
      fileInput.setAttribute("multiple", "true");
    };

    return fileInput;
  };

  #manageFiles(files) {
    this.#dispatchUploaderInputEvent(files);

    for(let i = 0; i < files.length; i++) {
      this.fileStorage.append(this.#createFilePreview(files.item(i)));
    };
  };

  #dispatchUploaderInputEvent(files) {
    this.uploaderInputTarget.files = files;
    this.uploaderInputTarget.dispatchEvent(new Event("input", { bubbles: true }));
  };

  #createFilePreview(file) {
    const fileIdentifier = `${file.lastModified}_${file.size}`;
    let filePreviewCard = document.createElement("div");

    addClass(filePreviewCard, "card pe-auto");
    filePreviewCard.style.width = "200px";
    filePreviewCard.style.cursor = "default";
    filePreviewCard.setAttribute("data-dropzone-role", "filePreview");
    filePreviewCard.setAttribute("data-action", "click->dropzone#stopClickPropagation");

    filePreviewCard.append(this.#createThumbnail(file, fileIdentifier));
    filePreviewCard.append(this.#createButtonGroup(file, fileIdentifier));

    return filePreviewCard;
  };

  #createThumbnail(file, fileIdentifier) {
    let thumbnailWrapper = document.createElement("div");
    addClass(thumbnailWrapper, "card-body d-flex justify-content-center");

    if (file.type == "application/pdf") {
      thumbnailWrapper.append(this.#createPdfThumbnail(fileIdentifier));
    } else if (file.type == "image/tiff") {
      thumbnailWrapper.append(this.#createTiffThumbnail(fileIdentifier));
    } else {
      thumbnailWrapper.append(this.#createImageThumbnail(file, fileIdentifier));
    };

    return thumbnailWrapper;
  };

  #createImageThumbnail(file, fileIdentifier) {
    let thumbnail = document.createElement("img");

    addClass(thumbnail, "img-fluid img-thumbnail");
    thumbnail.setAttribute("data-dropzone-identifier", fileIdentifier);
    thumbnail.setAttribute("data-action", "dblclick->cropper#cropImage");
    thumbnail.src = window.URL.createObjectURL(file);

    return thumbnail;
  };

  #createTiffThumbnail(fileIdentifier) {
    let thumbnail = document.createElement("h1");

    addClass(thumbnail, "bi bi-filetype-tiff");
    thumbnail.setAttribute("data-dropzone-identifier", fileIdentifier);

    return thumbnail;
  };

  #createPdfThumbnail(fileIdentifier) {
    let thumbnail = document.createElement("h1");

    addClass(thumbnail, "bi bi-filetype-pdf");
    thumbnail.setAttribute("data-dropzone-identifier", fileIdentifier);

    return thumbnail;
  };

  #createButtonGroup(file, fileIdentifier) {
    let buttonGroupWrapper = document.createElement("div");
    let buttonGroup = document.createElement("div");

    addClass(buttonGroupWrapper, "card-footer row justify-content-center");

    addClass(buttonGroup, "btn-group");

    if (this.croppable && file.type != "application/pdf" && file.type != "image/tiff") buttonGroup.append(this.#createCropButton());
    buttonGroup.append(this.#createRemoveButton(fileIdentifier));
    buttonGroupWrapper.append(buttonGroup);

    return buttonGroupWrapper;
  };

  #createCropButton() {
    let cropButton = document.createElement("button");
    let icon = document.createElement("i");

    addClass(cropButton, "btn btn-outline-light text-black");
    addClass(icon, "bi bi-scissors");

    cropButton.setAttribute("title", "Cortar");
    cropButton.setAttribute("data-cropper-anchor-param", ".card");
    cropButton.setAttribute("data-action", "cropper#cropClosestImage:stop:prevent click->cropper#changeModalTitle");
    cropButton.setAttribute("data-cropper-modal-title-param", "Modo de edição");

    cropButton.append(icon);

    return cropButton;
  };

  #createRemoveButton(fileIdentifier) {
    let removeButton = document.createElement("button");
    let icon = document.createElement("i");

    addClass(removeButton, "btn btn-outline-light text-black");
    addClass(icon, "bi bi-x-lg");

    removeButton.setAttribute("title", "Remover");
    removeButton.setAttribute("data-file-uploader-identifier-param", fileIdentifier);
    removeButton.setAttribute("data-action", "file-uploader#removeFile dropzone#removeFilePreview:stop");

    removeButton.append(icon);

    return removeButton;
  };

  #toggleDropzone() {
    if (!this.uploaderInputTarget.hasAttribute("multiple")) {
      if (this.dropzoneTarget.childElementCount > 1) {
        this.dropzoneTarget.classList.add("pe-none");
      } else {
        this.fileStorage.classList.remove("pe-none");
      };
    };
  };

  #removeExcedentFiles() {
    if (!this.uploaderInputTarget.hasAttribute("multiple")) {
      if (this.fileStorage.childElementCount > 2) this.fileStorage.removeChild(this.fileStorage.lastChild);
    };
  };
};
