import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="icon-toggler"
export default class extends Controller {
  toggle({ params: { icons } }) {
    let target = this.element.querySelector("i");
    let targetClass = target.getAttribute("class");

    if (icons.includes(targetClass)) {
      const index = icons.indexOf(targetClass);

      icons.splice(index, 1);
      target.setAttribute("class", icons[0]);
    };
  };
};
