import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

// Connects to data-controller="popover"
export default class extends Controller {

  popover;

  connect() {
    const popoverElement = this.element;
    this.popover = new bootstrap.Popover(popoverElement);
    if (this.#isTriggerManual()) this.popover.show();
  };

  disconnect() {
    if (this.#isTriggerManual()) this.popover.hide();
  };

  #isTriggerManual() {
    if (this.element.hasAttribute("data-bs-trigger")) {
      const trigger = this.element.getAttribute("data-bs-trigger");

      return trigger === "manual";
    } else {
      return false;
    };
  };
};
