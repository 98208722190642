import { Controller } from "@hotwired/stimulus"
import { signatureRedirector } from "../services/signature_redirector"

// Connects to data-controller="pje"
export default class extends Controller {
  static targets = ["content", "server", "callback", "reference"];

  connect() {
    this.contentTarget.click()
  }

  async signContent() {
    const content = this.contentTarget.getAttribute("data-content");
    const server = this.serverTarget.getAttribute("data-server");
    const callback = this.callbackTarget.getAttribute("data-callback");
    const reference = this.referenceTarget.getAttribute("data-reference");

    signatureRedirector(content, server, callback, reference);
  }
}
