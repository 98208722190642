import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="font-preview"
export default class extends Controller {
  static targets = ["selector", "styleElement", "preview"];

  selectorTargetConnected(target) {
    this.#createStyleElement(target.getAttribute("data-font-preview-id"));

    if (target.value) {
      this.#setStyle(target.getAttribute("data-font-preview-id"), target.value);
    };
    
    target.setAttribute("data-action", "input->font-preview#callSetStyle");
  };
  
  callSetStyle(event) {
    this.#setStyle(event.target.getAttribute("data-font-preview-id"), event.target.value);
  };

  #createStyleElement(selector_id) {
    const styleElement = document.createElement("style");
    styleElement.setAttribute("data-font-preview-target", "styleElement");
    styleElement.setAttribute("data-font-preview-selector-id", selector_id);
    this.element.append(styleElement);
  };

  #removeStyleAttribute(target) {
    target.removeAttribute("style");
  };

  #setStyle(selector_id, fontFamily) {
    const styleElements = this.styleElementTargets;
    const previews = this.previewTargets;

    styleElements.forEach((styleElement) => {
      if (!styleElement.hasAttribute("data-font-preview-selector-id")) {
        return;
      };

      if (styleElement.getAttribute("data-font-preview-selector-id") == selector_id) {
        if (fontFamily) {
          styleElement.textContent = `@import url('https://fonts.googleapis.com/css2?family=${fontFamily}');`;
        };
      };
    });

    previews.forEach((preview) => {
      if (!preview.hasAttribute("data-font-preview-selector-id")) {
        return;
      };

      if (preview.getAttribute("data-font-preview-selector-id") == selector_id) {
        if (fontFamily) {
          preview.setAttribute("style", `font-family: "${fontFamily}";`);
        } else {
          this.#removeStyleAttribute(preview);
        };
      };
    });
  };
};
