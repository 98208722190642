var movideskData = document.getElementById('movidesk-data');
var nameCode = "";

if (movideskData){
  nameCode = movideskData.getAttribute('data-name');
}

window.onload = () => {
  if (typeof movideskLogin == "function") {
    movideskLogin({
        name: nameCode,
        codeReference: nameCode,
        stayConnected: false,
        emptySubject: false,
        startChat: false,
    });
  }
}
