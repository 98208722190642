import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dynamic-visibility"
export default class extends Controller {
  static targets = ["selector", "renderable"];

  connect() {
    this.selectorTargets.forEach((target) => {
      if (target.type === "checkbox") {
        if (target.checked) this.#renderSelected(target.checked.toString());
      } else if (target.type === "radio") {
        if (target.checked) this.#renderSelected(target.value);
      } else {
        if (target.hasAttribute("data-dynamic-visibility-initial-render") || this.selectorTargets.length === 1) {
          this.#renderSelected(target.value);
        };
      };

      this.#setAction(target);
    });
  };

  renderableTargetConnected(target) {
    if (!target.style["display"]) {
      target.style["display"] = "none";
    };
  };

  callRenderSelected(event) {
    if (event.target.hasAttribute("data-dynamic-visibility-target")) {
      if (event.target.type == "checkbox") {
        this.#renderSelected(event.target.checked.toString());
      } else {
        this.#renderSelected(event.target.value);
      }
    } else {
      this.#renderSelected(event.target.closest("[data-dynamic-visibility-target=selector]").value);
    };
  };

  #renderSelected(selectedValue) {
    const targets = this.renderableTargets;

    const selectedRenderables = targets.filter(target => {
      const visibilityValue = target.getAttribute("data-dynamic-visibility-value");

      if (/,/.test(visibilityValue)) {
        const allowedValues = visibilityValue.split(",");

        return allowedValues.includes(selectedValue);
      } else {
        return visibilityValue == selectedValue;
      };
    });

    const rejectedRenderables = targets.filter(target => !selectedRenderables.includes(target));

    selectedRenderables.forEach((renderable) => {
      renderable.removeAttribute("style");
    });

    rejectedRenderables.forEach((renderable) => {
      renderable.style["display"] = "none";
    });
  };

  #setAction(target) {
    const actionMethod = target.getAttribute("data-dynamic-visibility-action-method") || "input";
    const actionCall = `${actionMethod}->dynamic-visibility#callRenderSelected`;
    let dataAction;

    if (target.hasAttribute("data-action")) {
      dataAction = `${target.getAttribute("data-action")} ${actionCall}`;
    } else {
      dataAction = actionCall;
    };

    target.setAttribute("data-action", dataAction);
  };
};
