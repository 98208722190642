export class TableColumn {
  constructor(index) {
    this.index = index;
    this.cells = [];
    this.sum = this.sumCells();
  };

  addCell(cell) {
    this.cells.push(cell);
  };

  getColumn() {
    return this.cells;
  };

  sumCells() {
    if (this.getColumn().length == 0) return "0,00";

    const sum = this.getColumn().reduce((total, cell) => {
      const rawValue     = cell.innerText;
      const numericValue = parseFloat(rawValue.replace(",", ".")) || 0;

      return total + numericValue;
    }, 0);

    this.sum = sum.toFixed(2).replace(".", ",");
  };
};
