import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs";

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      animation: 150,
      handle: ".handle",
      onEnd: this.updateRecordOrder.bind(this)
    });
  }

  updateRecordOrder(event) {
    const rows = this.element.querySelectorAll("tbody tr");
    rows.forEach((row, index) => {
      const input = row.querySelector("input[name*='[record_order]']");
      if (input) {
        input.value = index + 1;
      }
    });
  }
}

