import { Controller } from "@hotwired/stimulus"
import { differenceInYears, differenceInMonths, differenceInDays } from "date-fns";

// Connects to data-controller="time-span"
export default class extends Controller {
  static targets = ["start", "end", "timeInput", "typeInput"]

  startDate;
  endDate;
  elapsedTime;

  connect() {
    this.#setStartDate();
    this.#setEndDate();
  };

  calculate(event) {
    this.#clearErrorSignal();

    if (!this.startDate || !this.endDate) return this.#addErrorSignal();

    if (this.startDate > this.endDate) return this.#addErrorSignal();

    this.elapsedTime = {
      year: differenceInYears(this.endDate, this.startDate),
      month: differenceInMonths(this.endDate, this.startDate),
      day: differenceInDays(this.endDate, this.startDate)
    };

    if (this.hasTimeInputTarget) this.#setTimeInput();
    if (this.hasTypeInputTarget) this.#setTypeInput();
  };

  toISO8601(dateString) {
    const [day, month, year] = dateString.split("/");

    return `${year}-${month}-${day}T00:00:00-03:00`;
  };

  #setStartDate() {
    this.startTarget.addEventListener("input", (event) => {
      this.startDate = this.toISO8601(event.target.value);
    });
  };

  #setEndDate() {
    if (this.hasEndTarget) {
      this.endTarget.addEventListener("input", (event) => {
        this.endDate = this.toISO8601(event.target.value);
      });
    } else if (this.element.dataset.timeSpanServerTime) {
      this.endDate = this.element.dataset.timeSpanServerTime;
    };
  };

  #setTimeInput() {
    switch (true) {
      case (this.elapsedTime.year >= 1):
        this.timeInputTarget.value = this.elapsedTime.year;
        break;

      case (this.elapsedTime.month >= 1):
        this.timeInputTarget.value = this.elapsedTime.month;
        break;

      case (this.elapsedTime.day >= 1):
        this.timeInputTarget.value = this.elapsedTime.day;
        break;

      default:
        this.timeInputTarget.value = 0;
        break;
    };
  };

  #setTypeInput() {
    switch (true) {
      case (this.elapsedTime.year >= 1):
        this.typeInputTarget.value = 5;
        break;

      case (this.elapsedTime.month >= 1):
        this.typeInputTarget.value = 4;
        break;

      case (this.elapsedTime.day >= 1):
        this.typeInputTarget.value = 3;
        break;

      default:
        this.typeInputTarget.value = 2;
        break;
    };
  };

  #addErrorSignal() {
    this.timeInputTarget.dataset["stimulusValidationErrorSignal"] = "";
    this.#dispatchEventOnInputTarget();
  };

  #clearErrorSignal() {
    delete this.timeInputTarget.dataset.stimulusValidationErrorSignal;
    this.#dispatchEventOnInputTarget();
  };

  #dispatchEventOnInputTarget() {
    this.timeInputTarget.dispatchEvent(new Event("input", { bubbles: true }));
  };
};
