import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="disable-enter-key"
export default class extends Controller {
  static targets = ["disable"];

  connect() {
    this.disableTargets.forEach((target) => {
      target.addEventListener("keydown", (event) => {
        if (event.key === "Enter" && !event.target.matches("trix-editor") && !event.target.matches("textarea")) {
          event.preventDefault();
        };
      });
    });
  };
};
