import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="password-visibility"
export default class extends Controller {
  static targets = ["input"];

  toggle() {
    this.inputTargets.forEach((target) => {
      if (target.type === "password") {
        target.type = "text";
      } else {
        target.type = "password";
      };
    });
  };
};
