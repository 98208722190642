import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="url-query"
export default class extends Controller {
  static targets = ["link", "paramSetter"];

  urls = [];

  connect() {
    this.paramSetterTargets.forEach((target) => {
      this.#updateUrl(target);
      this.#addScope(target);
      this.#updateLinkTargetHref(target);
    });
  };

  linkTargetConnected(target) {
    this.urls.push({
      url: new URL(target.href),
      elementId: target.id
    });
  };

  paramSetterTargetConnected(target) {
    target.setAttribute("data-action", "url-query#updateLinkHref");
  };

  updateLinkHref(event) {
    this.#updateUrl(event.target);
    this.#addScope(event.target);
    this.#updateLinkTargetHref(event.target);
  };

  #updateUrl(target) {
    let value = target.value;

    if (target.type == "checkbox" && !target.checked) {
      value = "";
    };

    const queryParam = target.getAttribute("data-url-query-bond-param");

    this.#setterTargets(target).forEach((elementId) => {
      this.urls.forEach((url, index) => {
        if (elementId == url.elementId) {
          let href = new URLSearchParams(url.url.search);

          if (href.has(queryParam)) {
            href.set(queryParam, value);
          };

          this.urls[index].url.search = href.toString();
        };
      });
    });
  };

  #addScope(target) {
    if (!target.hasAttribute("data-url-query-scope-param")) return null;

    const scopes = target.getAttribute("data-url-query-scope-param").split(" ");

    if (!target.type == "checkbox") return null;

    if (!target.checked) return null;

    this.#setterTargets(target).forEach((elementId) => {
      this.urls.forEach((url, index) => {
        if (elementId == url.elementId) {
          let href = new URLSearchParams(url.url.search);

          if (target.checked) {
            scopes.forEach(scope => href.set("scopes[]", scope));
          } else {
            href.delete("scopes[]");
          };

          this.urls[index].url.search = href.toString();
        };
      })
    })
  }

  #updateLinkTargetHref(target) {
    this.#setterTargets(target).forEach((elementId) => {
      this.linkTargets.forEach((linkTarget) => {
        if (linkTarget.id == elementId) {
          let href = this.urls.filter(url => url.elementId == elementId)[0].url.href;

          linkTarget.href = href;
        };
      });
    });
  };

  #setterTargets(target) {
    return target.getAttribute("data-url-query-target-links").split(" ");
  };
};
