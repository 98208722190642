import { Modal } from "bootstrap"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["enterTriggered", "focus"];

  modal;

  connect() {
    let backdrop = document.querySelector(".modal-backdrop");
    if (backdrop) {
      backdrop.remove();
    };

    this.#disableEnterKey();

    if (this.hasFocusTarget) {
      this.#focusOnLoad();
    };

    this.modal = new Modal(this.element);
    this.modal.show();
  };

  disconnect() {
    document.getElementsByTagName("body")[0].removeAttribute("style");
  };

  hideModal({ params: { remove } }) {
    this.modal.hide();

    if (remove) this.element.remove();
  };

  toggleModalFullscreen() {
    let modalDialog = document.querySelector(".modal-dialog");

    if (modalDialog.classList.contains("modal-fullscreen")) {
      modalDialog.classList.remove("modal-fullscreen");
      document.cookie = "modal_fullscreen=; path=/;";
    } else {
      modalDialog.classList.add("modal-fullscreen");
      document.cookie = "modal_fullscreen=modal-fullscreen; path=/;";
    };
  };

  onEnterKeyPressed() {
    this.enterTriggeredTarget.click();
  };

  #disableEnterKey() {
    this.element.setAttribute("onkeydown", "return event.key != 'Enter';");
  };

  #focusOnLoad() {
    this.element.addEventListener("shown.bs.modal", function () {
      document.querySelector("[data-modal-target=focus]").focus();
    });
  };
};
