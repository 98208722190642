import { Controller } from "@hotwired/stimulus"
import { currencyMask } from "./../services/currencyMask"

// Connects to data-controller="virtual-field"
export default class extends Controller {
  expression;
  form;
  variables;

  connect() {
    this.updateVirtualField();
  };

  #isValidInput(input, variable) {
    const inputName = input.name;

    if (!inputName.includes(variable)) return false;

    const inputPreffix = inputName.substring(0, inputName.indexOf(variable));

    if (this.element.getAttribute("name").includes(inputPreffix)) {
      return true;
    } else {
      return false;
    };
  };

  #formControlNodeList() {
    return this.form.querySelectorAll("input.form-control");
  };

  #setAttributes() {
    this.expression = this.element.getAttribute("data-virtual-field-expression");
    this.form = this.element.closest("form");
    this.variables = this.expression.replace(/[{()}]/g, "").replace(/[+*\/-]/g, "").match(/("[^"]+"|[^"\s]+)/g);
  };

  #setVariables() {
    let InputsIndex = Object.keys(this.#formControlNodeList());

    InputsIndex.forEach((index) => {
      this.variables.forEach((variable) => {
        const input = this.#formControlNodeList()[index];

        if (this.#isValidInput(input, variable)) {
          const stringExpression = `parseInt(document.getElementById("${this.form.id}").querySelectorAll("input.form-control")[${index}].value.replace(/\\D/g, ""))`;
          this.expression = this.expression.replace(variable, stringExpression);

          input.addEventListener("change", () => {
            this.updateVirtualField();
          });
        };
      });
    });
  };

  updateVirtualField() {
    this.element.innerText = currencyMask(this.#calculateExpression().toString());
    this.element.dispatchEvent(new Event("change", { bubbles: true }));
  };

  #calculateExpression() {
    this.#setAttributes();
    this.#setVariables();

    var indirectEval = eval;
    return indirectEval(this.expression);
  };
};
