import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="title-toggler"
export default class extends Controller {
  toggle({ params: { titles } }) {
    let target = this.element;
    let targetTitle = target.getAttribute("title");

    if (titles.includes(targetTitle)) {
      const index = titles.indexOf(targetTitle);

      titles.splice(index, 1);
      target.setAttribute("title", titles[0]);
    };
  };
};
