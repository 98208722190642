import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["submitButton"];

  connect() {
    this.disabledButton();
    setInterval(() => this.verifyButton(), 1000);
  }

  disabledButton() {
    this.submitButtonTarget.disabled = true;
  }

  enableButon() {
    this.submitButtonTarget.disabled = false;
  }

  verifyButton() {
    const recaptchaResponse = document.querySelectorAll('[name="g-recaptcha-response"]')[0]?.value ? { value: document.querySelectorAll('[name="g-recaptcha-response"]')[0].value } : '';
    if (!recaptchaResponse) {
      this.disabledButton();
      return;
    }
    this.enableButon();
  }
}



