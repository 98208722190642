export function setCookie(name, value, { days = 0, path = null } = {}) {
  let expires    = "";
  let cookiePath = "";

  if (typeof days === "number" && days > 0) {
    const date = new Date();

    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = `; expires=${date.toUTCString()}`;
  }

  if (path) cookiePath = `; path=${path}`;

  document.cookie = `${name}=${value || ""}${expires}${cookiePath}`;
};

export function getCookie(name) {
  const cookieName = `${name}=`;
  const cookies    = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];

    while (cookie.charAt(0) === " ") cookie = cookie.substring(1, cookie.length);

    if (cookie.indexOf(cookieName) === 0) return cookie.substring(cookieName.length, cookie.length);
  };

  return false;
};

export function eraseCookie(name, path = null) {
  let cookiePath = "";

  if (path) cookiePath = `; path=${path}`;

  document.cookie = `${name}=; Max-Age=-99999999${cookiePath}`;
}
