import { Controller } from "@hotwired/stimulus"
import { SortableTable } from "../services/orderable.js"

// Connects to data-controller="orderable"
export default class extends Controller {
  connect() {
    var sortableTables = 0;
    var sortableTables = document.querySelectorAll('table.sortable');

    for (var i = 0; i < sortableTables.length; i++) {
      new SortableTable(sortableTables[i]);
    }
    
    const buttons = document.querySelectorAll('#sortable');
    
    buttons.forEach((button, index) => {
      if (index === 0) {
        button.classList.add('down');
      }
    
      button.addEventListener('click', () => {
        buttons.forEach(btn => {
          if (btn !== button) {
            btn.classList.remove('up', 'down');
          }
        });
    
        if (!button.classList.contains('up')) {
          button.classList.remove('down');
          button.classList.add('up');
        } else {
          button.classList.remove('up');
          button.classList.add('down');
        }
      });
    });
  }
}
