import { Controller } from "@hotwired/stimulus"
import { validateCPF } from "../services/doc_cpf_validate";
import { validateCNPJ } from "../services/doc_cnpj_validate";

export default class extends Controller {
  static targets = ["message"]

  connect() {
    const inputElements = this.element.querySelectorAll("input");
    const validationFunctions = {
      "doc_cpf_cnpj": this.validate_cpf_cnpj.bind(this),
      "doc_cpf": this.validate_cpf.bind(this),
      "doc_cnpj": this.validate_cnpj.bind(this),
    };
  
    inputElements.forEach(input => {
      input.addEventListener("blur", (event) => {
        const inputClass = Array.from(input.classList).find(className => /^doc_/i.test(className));
        const validationFunction = validationFunctions[inputClass];
        if (validationFunction) {
          validationFunction(event);
        }
      });
    });
  }

  async validate_cpf_cnpj(event) {
    var eventTarget = event.target.value;
    var eventType = event.type
    
    var isValid = true;

    if (!eventTarget) {
      return;
    }

    if (eventTarget.length === 18) {
        isValid = await validateCNPJ(eventTarget);
    } else if (eventTarget.length === 14) {
        isValid = await validateCPF(eventTarget);
    }

    if (eventType == "blur" && !isValid){
      this.#isvalid(false); 
    }
  }

  async validate_cpf(event){
    var eventTarget = event.target.value;
    var eventType = event.type

    if (!eventTarget) {
      return;
    }

    if (!eventTarget.trim()) {
      this.#isvalid(true);
    }

    if (eventTarget.length < 14) {
      this.#isvalid(true); 
    }

    var isValid = await validateCPF(eventTarget);

    if (eventType == "blur" && !isValid){
      this.#isvalid(false); 
    }
  }

  async validate_cnpj(event){
    var eventTarget = event.target.value;
    var eventType = event.type

    if (!eventTarget) {
      return;
    }

    if (!eventTarget.trim()) {
      this.#isvalid(true); 
      return;
    }

    if (eventTarget.length < 18) {
      this.#isvalid(true); 
      return;
    }

    var isValid = await validateCNPJ(eventTarget);

    if (eventType == "blur" && !isValid){
      this.#isvalid(false); 
    }
  }

  #isvalid(isValid){
    if (isValid) {
      this.messageTarget.setAttribute("hidden", "");
      this.messageTarget.parentNode.classList.remove("d-block")
    } else {
      this.messageTarget.parentNode.classList.add("d-block")
      this.messageTarget.removeAttribute("hidden");
    }
  }
}
