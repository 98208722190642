import { Controller } from "@hotwired/stimulus"
import Choices from "choices.js"

export default class extends Controller {
  static targets = ["choice", "searchLimit", "collection"];

  choice;
  collection;
  filter = [];

  connect() {
    const searchLimit = this.hasSearchLimitTarget ? this.searchLimitTarget.getAttribute("data-searchLimit") : 2;

    this.choice = new Choices(this.choiceTarget, {
      searchResultLimit: searchLimit,
      removeItemButton: true,
      noResultsText: "Pressione Enter duas vezes para selecionar",
      placeholderValue: "",
      addItemText: (value) => {
        return `Pressione Enter para adicionar <b>"${value}"</b>`;
      },
      itemSelectText: "Pressione Enter para selecionar",
      loadingText: "Carregando...",
      noChoicesText: "Digite o texto a ser pesquisado e adicionado",
      uniqueItemText: "Somente valores exclusivos podem ser adicionados",
      customAddItemText: "Somente valores que correspondam a condições específicas podem ser adicionados",
      maxItemText: (maxItemCount) => {
        return `Apenas ${maxItemCount} valores podem ser adicionados`;
      },
      valueComparer: (value1, value2) => {
        return value1 === value2;
      },
      classNames: {
        item: "choices__item white-space-pre-wrap"
      }
    });

    this.#setChoices();

    if (this.choiceTarget.getAttribute("data-choices-custom-input") == "true") {
      this.element.querySelector("[name=search_terms]").setAttribute("data-action", "keydown.enter->choices#addOption")
    };

    const tableResponsiveElement = this.choice.passedElement.element.closest(".table-responsive");

    this.choice.passedElement.element.addEventListener("showDropdown", (event) => {
      if (tableResponsiveElement) tableResponsiveElement.classList.remove("table-responsive");
    });

    this.choice.passedElement.element.addEventListener("hideDropdown", (event) => {
      if (tableResponsiveElement) tableResponsiveElement.classList.add("table-responsive");
    });
  };

  addOption(event) {
    const inputValue = event.target.value;

    if (inputValue) {
      this.choice.setChoices([
        { value: inputValue, label: inputValue },
      ],
      "value",
      "label",
      false);
    };
  };

  collectionTargetConnected(target) {
    this.#setCollection(target);
  };

  toggleFilter({ params: { keyword } }) {
    const item = keyword;

    if (this.filter.includes(item)) {
      const itemIndex = this.filter.indexOf(item);
      this.filter.splice(itemIndex, 1);
    } else {
      this.filter.push(item);
    };

    this.#useFilter(this.filter);
  };

  #useFilter(filter) {
    this.#setCollection(this.collectionTarget, filter);
    this.#setChoices();
  };

  #setCollection(target, filter = []) {
    let options = Object.keys(target.children);
    let collection = [];

    options.forEach((index) => {
      if (target.children[index].nodeName == "OPTGROUP") {
        if (filter.length != 0 && !filter.includes(target.children[index].label)) {
          return;
        };

        let subOptions = Object.keys(target.children[index].children);

        subOptions.forEach((subIndex) => {
          collection.push({ value: target.children[index].children[subIndex].value, label: target.children[index].children[subIndex].label });
        });
      } else {
        collection.push({ value: target.children[index].value, label: target.children[index].label });
      };
    });

    this.collection = collection;
  };

  #setChoices() {
    if(this.collection != undefined) {
      this.choice.setChoices(this.collection, "value", "label", true);
    };
  };
};
