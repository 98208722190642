import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="jivo-chat"
export default class extends Controller {
  static targets = ["userInfo"];
    connect(){
      const user = this.userInfoTarget.getAttribute("user")
      const email = this.userInfoTarget.getAttribute("email")
      window.onload = () => {
        const intervalId = setInterval(() => {
          if (window.jivo_api) {
            clearInterval(intervalId);
            window.jivo_api.setContactInfo({
              name: user,
              email: email,
              });
          }
        }, 200);
      }
    }
}
