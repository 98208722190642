import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="event-dispatcher"
export default class extends Controller {

  triggers = {
    "initialize": this.#initializeTrigger.bind(this),
    "change": this.#changeTrigger.bind(this),
  };

  connect() {
    if (this.element.hasAttribute("data-event-dispatcher-triggers")) {
      const elementTriggers = this.element.getAttribute("data-event-dispatcher-triggers").split(" ");

      Object.keys(this.triggers).forEach((trigger) => {
        if (!elementTriggers.includes(trigger)) return;

        this.triggers[trigger]();
      });
    };
  };

  #dispatch() {
    this.element.dispatchEvent(new Event(this.#getEvent(), { bubbles: true }));
  };

  #getEvent() {
    if (!this.element.hasAttribute("data-event-dispatcher-event")) return "input";

    return this.element.getAttribute("data-event-dispatcher-event");
  };

  #initializeTrigger() {
    this.#dispatch();
  };

  #changeTrigger() {
    this.element.addEventListener("change", () => {
      this.#dispatch();
    });
  };
};
