import { Controller } from "@hotwired/stimulus";
import 'tiff.js';

export default class extends Controller {
  static targets = ["container"];

  connect() {
    const tiffUrl = this.element.getAttribute("data-tiff-viewer-url-value");
    this.loadTiffImage(tiffUrl);
  };

  loadTiffImage(url) {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.responseType = 'arraybuffer';

    xhr.onload = (e) => {
      var buffer = xhr.response;
      var tiff = new Tiff({ buffer: buffer });

      for (var i = 0, len = tiff.countDirectory(); i < len; ++i) {
        tiff.setDirectory(i);
        var canvas = tiff.toCanvas();
        const dataURL = canvas.toDataURL("image/png");
        const img = document.createElement("img");
        img.src = dataURL;

        img.classList.add("img-fluid", "row", "border");

        if (i > 0) img.classList.add("mt-2");

        if (this.containerTarget.hasAttribute("data-tiff-viewer-zoom")) {
          img.classList.add("cursor-pointer");
          img.setAttribute("data-action", "click->cropper#modalExtraLarge click->cropper#cropImage click->cropper#changeModalTitle");
          img.setAttribute("data-cropper-modal-title-param", "Modo de zoom");
        }

        this.containerTarget.appendChild(img);
      };
    };

    xhr.send();
  };
};
