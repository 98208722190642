import { pjeConnection } from "./pje_connection"
import { redirectTo } from "./redirect_to";

export async function signatureRedirector(content, server, callback, reference, algorithm = "SHA256withRSA") {
  const pje_response = await pjeConnection(content, server, reference, algorithm);
  const query = `session=${pje_response["session"]}`;
  if (pje_response["error"]  == 0) {
    redirectTo(`${server}/${callback}?${query}`);
  } else {
    redirectTo(`${server}/browser_errors?error=${pje_response["error"]}&error_message=${pje_response["error_message"]}`);
  };
};
