import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="file-uploader"
export default class extends Controller {
  static targets = ["input", "files"];

  inputTargetConnected(target) {
    target.setAttribute("data-action", "file-uploader#addFile");
  };

  addFile(event) {
    let input = event.target;
    const isMultiple = input.hasAttribute("multiple");
    let filesIndex = Object.keys(input.files);

    filesIndex.forEach((fileIndex) => {
      if (!isMultiple && this.filesTarget.childElementCount > 0) return;

      this.filesTarget.append(this.#createFileInput(input.files[fileIndex]));
    });

    this.#clearFileStorage(input);
  };

  removeFile({ params: { identifier } }) {
    const fileIdentifier = identifier;
    const fileInput = this.filesTarget.querySelector(`[data-file-uploader-identifier="${fileIdentifier}"]`);
    fileInput.remove();
  };

  #createFileInput(file) {
    let dataTransfer = new DataTransfer();
    let fileInput = this.inputTarget.cloneNode();

    dataTransfer.items.add(file);
    fileInput.files = dataTransfer.files;
    fileInput.setAttribute("data-file-uploader-identifier", `${file.lastModified}_${file.size}`);
    fileInput.setAttribute("hidden", true);

    return fileInput;
  };

  #clearFileStorage(input) {
    let dataTransfer = new DataTransfer();
    input.files = dataTransfer.files;
  };
};
