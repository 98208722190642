export async function validateCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '');
  
    if (cnpj.length !== 14) {
      return false;
    }
  
    if (/^(\d)\1+$/.test(cnpj)) {
      return false;
    }
  
    let length = cnpj.length - 2;
    let numbers = cnpj.substring(0, length);
    let digits = cnpj.substring(length);
    let sum = 0;
    let pos = length - 7;
  
    for (let i = length; i >= 1; i--) {
      sum += numbers.charAt(length - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
  
    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  
    if (result !== parseInt(digits.charAt(0), 10)) {
      return false;
    }
  
    length = length + 1;
    numbers = cnpj.substring(0, length);
    digits = cnpj.substring(length);
    sum = 0;
    pos = length - 7;
  
    for (let i = length; i >= 1; i--) {
      sum += numbers.charAt(length - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
  
    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  
    return result === parseInt(digits.charAt(0), 10);
  }
  