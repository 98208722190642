import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="decimal"
export default class extends Controller {
  static targets = ["decimal"];
  hasDecimal = false;

  connect() {
    this.element.addEventListener("keydown", this.handleKeydown.bind(this));
    this.element.addEventListener("input", this.handleInput.bind(this));
    this.element.addEventListener("keyup", this.handleKeyup.bind(this));
  }

  handleKeydown(event) {
    if (event.ctrlKey || event.metaKey || event.altKey) {
      return;
    }

    if (
      event.keyCode >= 8 && event.keyCode <= 46 ||
      event.keyCode >= 35 && event.keyCode <= 40
    ) {
      if (/^[a-zA-Z]$/.test(event.key)) {
        event.preventDefault();
      }  
      return;
    }

    if (/^[a-zA-Z]$/.test(event.key)) {
      event.preventDefault();
    }

    if ((event.key === "." || event.key === ",") && this.hasDecimal) {
      event.preventDefault();
    }

    if (isNaN(event.key)){
      if (event.key === "." || event.key === ","){
        return;
      }
      event.preventDefault();
    }
  }

  handleInput() {
    const value = this.element.value;
    this.hasDecimal = value.includes(".") || value.includes(",");
  }

  handleKeyup(event) {
    if (event.key === "." || event.key === ",") {
      this.hasDecimal = true;
    }
  }
}
