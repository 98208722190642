document.addEventListener("DOMContentLoaded", function() {
    var numberInputs = document.querySelectorAll('input[type="number"]');
  
    numberInputs.forEach(function(input) {
      var maxLength = parseInt(input.getAttribute('maxlength'));
  
      input.addEventListener("input", function() {
        if (this.value.length > maxLength) {
          this.value = this.value.slice(0, maxLength);
        }
      });
    });
  });