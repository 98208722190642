export async function pjeConnection(message, server, reference, algorithm = "SHA256withRSA") {
  const session = `${Date.now().toString()}_${parseInt(Math.random() * 100000000)}`;

  const task = {
    "algoritmoAssinatura": algorithm,
    "mensagem": message,
    "enviarPara": `api/v1/pje_landing_zone?session=${session}&reference=${reference}`
  };

  const requisition = {
      "aplicacao": "Acsiv",
      "tarefaId": "cnj.autenticador",
      "tarefa": JSON.stringify(task),
      "servidor": `${server}/`
  };

  var encodedRequisition = JSON.stringify(requisition);
  encodedRequisition = encodeURIComponent(encodedRequisition);

  var data = new Date();
  const time = data.getTime();

  const config = {
    method: 'POST',
    headers: {
      'Accept': '*'
    },
    mode: 'cors',
    cache: 'default'
  };

  try {
    const url = `http://localhost:8800/pjeOffice/requisicao/?r=${encodedRequisition}&u=${time}`;
    const response = await fetch(url, config);
  } catch (error) {
    if (error.message === 'R_BLOCKED_BY_CLIENT') {
      message = "Connection blocked, check ad settings"
    } else {
      message = "An error occurred when trying to connect";
    };

    return {session: session, error: message, error_message: error.message};
  };

  return {session: session, error: 0};
};
